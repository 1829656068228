import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { UserProvider } from "./utiles/Context/UserContext";

const rootElement = document.getElementById("root");
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');


ReactDOM.render(
    <UserProvider > {/*Componente que provee el contexto global a la aplicacion */}
        <BrowserRouter basename={ baseUrl }>
            <App />
        </BrowserRouter>
    </UserProvider>,
    rootElement
);

registerServiceWorker();
