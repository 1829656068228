import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router';
import Loading from './components/Util/Loading';

// Pages
const Login = React.lazy(() => import('../src/pages/Login'));
const Logout = React.lazy(() => import('../src/pages/Logout'));
const Layout = React.lazy(() => import('../src/pages/Layout'));
const notFound = React.lazy(() => import('../src/components/Util/notFound'));

export default class App extends Component {
    static displayName = App.name;

    render() {

        const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

        console.log(baseUrl);

        return (
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/notFound" component={notFound} />
                    <Route path="/" component={Layout} />
                </Switch>
            </Suspense>
        );
    }
}